import React, { useEffect } from "react";
import "react-image-lightbox/style.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { history } from "./_helpers/history";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const Home = React.lazy(() => import("./views/pages/home/Home"));
const Tunai = React.lazy(() => import("./views/pages/tunai/Tunai"));
const Transfer = React.lazy(() => import("./views/pages/transfer/Transfer"));
const TarikanTunai = React.lazy(() =>
  import("./views/pages/tarikanTunai/TarikanTunai")
);
const SetoranTunai = React.lazy(() =>
  import("./views/pages/setoranTunai/SetoranTunai")
);
const Pemindahbukuan = React.lazy(() =>
  import("./views/pages/pemindahbukuan/Pemindahbukuan")
);
const TransferAntarBank = React.lazy(() =>
  import("./views/pages/transferAntarBank/TransferAntarBank")
);
const ReferenceNumber = React.lazy(() =>
  import("./views/pages/refference/ReferenceNumber")
);
const Page403 = React.lazy(() => import("./views/pages/page403/Page403"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

export function App() {
  useEffect(() => {
    const subscribeUser = async () => {
      try {
        const registration = await navigator.serviceWorker.ready;

        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: "YOUR_VAPID_PUBLIC_KEY",
        });

        // Send the subscription to your server
        // axios.post('/api/subscribe', { subscription });

        // For demo purposes, you can use a fake endpoint
        fetch("/push-notification", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subscription }),
        });
      } catch (error) {
        console.error("Error subscribing to push notifications:", error);
      }
    };

    subscribeUser();
  }, []);

  return (
    <BrowserRouter history={history}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/home"
            name="Home Page"
            render={(props) => <Home {...props} />}
          />
          <Route
            exact
            path="/menu/tunai"
            name="Transaksi Tunai"
            render={(props) => <Tunai {...props} />}
          />
          <Route
            exact
            path="/menu/transfer"
            name="Transfer"
            render={(props) => <Transfer {...props} />}
          />
          <Route
            exact
            path="/tarikan-tunai"
            name="Transaksi Tunai"
            render={(props) => <TarikanTunai {...props} />}
          />
          <Route
            exact
            path="/setoran-tunai"
            name="Setoran Tunai"
            render={(props) => <SetoranTunai {...props} />}
          />
          <Route
            exact
            path="/pemindahbukuan"
            name="Pemindahbukuan"
            render={(props) => <Pemindahbukuan {...props} />}
          />
          <Route
            exact
            path="/transfer-ke-bank-lain"
            name="Transfer ke Bank Lain"
            render={(props) => <TransferAntarBank {...props} />}
          />
          <Route
            exact
            path="/reference-number/:refNumber"
            name="Reference Number"
            render={(props) => <ReferenceNumber {...props} />}
          />
          <Route
            exact
            path="/403"
            name="Page 403"
            render={(props) => <Page403 {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Redirect to="/home" />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
